import { FieldBaseComponent } from './../field-base'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-single-choice-field',
  templateUrl: './single-choice-field.component.html',
  styleUrls: ['./single-choice-field.component.scss']
})
export class SingleChoiceFieldComponent extends FieldBaseComponent implements OnInit {
  constructor() {
    super()
  }
}
