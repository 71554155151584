import { Injectable } from '@angular/core'
import { config } from './../config'

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor() {}

  toAbsolute(url: string, base: string) {
    return new URL(url, base).href.replace(/\/$/, '')
  }

  getCanonical() {
    return this.toAbsolute(window.location.pathname, config.canonicalBase)
  }
}
