<nav
  class="navbar navbar-expand-lg navbar-dark sticky-top"
  [ngStyle]="navStyle"
>
  <div class="container">
    <a class="navbar-brand" [routerLink]="'/'">{{ org.name }}</a>

    <button
      class="navbar-toggler"
      type="button"
      (click)="isMenuCollapsed = !isMenuCollapsed"
    >
      &#9776;
    </button>

    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav align-items-center d-flex ml-auto">
        <li
          class="nav-item"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a
            class="nav-link"
            [routerLink]="'/'"
            (click)="isMenuCollapsed = true"
          >
            All Events
          </a>
        </li>

        <li class="nav-item" *ngIf="!user">
          <a class="nav-link" href="javascript:void(0)" (click)="login()">
            Login
          </a>
        </li>

        <li class="nav-item" ngbDropdown placement="bottom-right" *ngIf="user">
          <a
            class="nav-link"
            ngbDropdownToggle
            role="button"
            class="py-0"
            id="profile-dropdown"
          >
            <img
              alt="Image placeholder"
              class="rounded-circle"
              width="40"
              height="40"
              [src]="user.picture"
            />
          </a>
          <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="profile-dropdown"
          >
            <div class="dropdown-header">
              <h6 class="text-overflow m-0">Welcome {{ user.email }}!</h6>
            </div>

            <!-- <a ngbDropdownItem routerLinkActive="active" [routerLink]="'.'">
              My profile
            </a> -->

            <div class="dropdown-divider"></div>

            <a ngbDropdownItem href="javascript:void(0)" (click)="logout()">
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
