<!-- Text Based Input -->
<div class="form-group">
  <label [for]="fieldId">
    {{ field.label }}
    <span *ngIf="field.required" class="text-danger">*</span>
  </label>
  <input
    type="text"
    class="form-control form-control-alternative"
    [ngClass]="invalidClass"
    [id]="fieldId"
    [required]="field.required"
    [formControl]="control"
    [attr.aria-describedby]="field.hintRendered ? 'help-' + fieldId : null"
  />
  <div class="invalid-feedback">
    This field is required
  </div>
  <small
    *ngIf="field.hintRendered"
    [id]="'help-' + fieldId"
    class="form-text text-muted"
    [innerHtml]="field.hintRendered"
  ></small>
</div>
