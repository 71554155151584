import { Injectable } from '@angular/core'

export interface IRegion {
  name: string
  id: string
}

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  countries: IRegion[] = [
    {
      name: 'United States',
      id: 'US'
    },
    {
      name: 'Canada',
      id: 'CA'
    }
  ]
  regions: { [countryCode: string]: IRegion[] } = {
    US: [
      {
        name: 'Alabama',
        id: 'AL'
      },
      {
        name: 'Alaska',
        id: 'AK'
      },
      {
        name: 'Arizona',
        id: 'AZ'
      },
      {
        name: 'Arkansas',
        id: 'AR'
      },
      {
        name: 'California',
        id: 'CA'
      },
      {
        name: 'Colorado',
        id: 'CO'
      },
      {
        name: 'Connecticut',
        id: 'CT'
      },
      {
        name: 'Delaware',
        id: 'DE'
      },
      {
        name: 'District Of Columbia',
        id: 'DC'
      },
      {
        name: 'Florida',
        id: 'FL'
      },
      {
        name: 'Georgia',
        id: 'GA'
      },
      {
        name: 'Hawaii',
        id: 'HI'
      },
      {
        name: 'Idaho',
        id: 'ID'
      },
      {
        name: 'Illinois',
        id: 'IL'
      },
      {
        name: 'Indiana',
        id: 'IN'
      },
      {
        name: 'Iowa',
        id: 'IA'
      },
      {
        name: 'Kansas',
        id: 'KS'
      },
      {
        name: 'Kentucky',
        id: 'KY'
      },
      {
        name: 'Louisiana',
        id: 'LA'
      },
      {
        name: 'Maine',
        id: 'ME'
      },
      {
        name: 'Maryland',
        id: 'MD'
      },
      {
        name: 'Massachusetts',
        id: 'MA'
      },
      {
        name: 'Michigan',
        id: 'MI'
      },
      {
        name: 'Minnesota',
        id: 'MN'
      },
      {
        name: 'Mississippi',
        id: 'MS'
      },
      {
        name: 'Missouri',
        id: 'MO'
      },
      {
        name: 'Montana',
        id: 'MT'
      },
      {
        name: 'Nebraska',
        id: 'NE'
      },
      {
        name: 'Nevada',
        id: 'NV'
      },
      {
        name: 'New Hampshire',
        id: 'NH'
      },
      {
        name: 'New Jersey',
        id: 'NJ'
      },
      {
        name: 'New Mexico',
        id: 'NM'
      },
      {
        name: 'New York',
        id: 'NY'
      },
      {
        name: 'North Carolina',
        id: 'NC'
      },
      {
        name: 'North Dakota',
        id: 'ND'
      },
      {
        name: 'Ohio',
        id: 'OH'
      },
      {
        name: 'Oklahoma',
        id: 'OK'
      },
      {
        name: 'Oregon',
        id: 'OR'
      },
      {
        name: 'Pennsylvania',
        id: 'PA'
      },
      {
        name: 'Rhode Island',
        id: 'RI'
      },
      {
        name: 'South Carolina',
        id: 'SC'
      },
      {
        name: 'South Dakota',
        id: 'SD'
      },
      {
        name: 'Tennessee',
        id: 'TN'
      },
      {
        name: 'Texas',
        id: 'TX'
      },
      {
        name: 'Utah',
        id: 'UT'
      },
      {
        name: 'Vermont',
        id: 'VT'
      },
      {
        name: 'Virginia',
        id: 'VA'
      },
      {
        name: 'Washington',
        id: 'WA'
      },
      {
        name: 'West Virginia',
        id: 'WV'
      },
      {
        name: 'Wisconsin',
        id: 'WI'
      },
      {
        name: 'Wyoming',
        id: 'WY'
      }
    ],
    CA: [
      {
        name: 'Alberta',
        id: 'AB'
      },
      {
        name: 'British Columbia',
        id: 'BC'
      },
      {
        name: 'Manitoba',
        id: 'MB'
      },
      {
        name: 'New Brunswick',
        id: 'NB'
      },
      {
        name: 'Newfoundland and Labrador',
        id: 'NL'
      },
      {
        name: 'Northwest Territories',
        id: 'NT'
      },
      {
        name: 'Nova Scotia',
        id: 'NS'
      },
      {
        name: 'Nunavut',
        id: 'NU'
      },
      {
        name: 'Ontario',
        id: 'ON'
      },
      {
        name: 'Prince Edward Island',
        id: 'PE'
      },
      {
        name: 'Quebec',
        id: 'QC'
      },
      {
        name: 'Saskatchewan',
        id: 'SK'
      },
      {
        name: 'Yukon Territory',
        id: 'YT'
      }
    ]
  }

  constructor() {}

  getCountryByRegion(region: string): string {
    for (const countryCode in this.regions) {
      if (this.regions[countryCode]) {
        const foundRegion = this.regions[countryCode].find(
          reg => reg.id === region
        )

        if (foundRegion) {
          return countryCode
        }
      }
    }
  }
}
