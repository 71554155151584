import { FieldBaseComponent } from './../field-base'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent extends FieldBaseComponent implements OnInit {
  constructor() {
    super()
  }
}
