<form [formGroup]="form">
  <div *ngIf="customForm.showHeader" class="mt-5">
    <h6>{{ customForm.name }}</h6>
    <p [innerHtml]="customForm.descriptionRendered"></p>
  </div>

  <div *ngFor="let field of fields">
    <app-text-field
      *ngIf="field.type === CustomFormFieldType.text"
      [field]="field"
      [form]="form"
      [id]="id"
    ></app-text-field>

    <app-text-field-multi
      *ngIf="field.type === CustomFormFieldType.textMulti"
      [field]="field"
      [form]="form"
      [id]="id"
    ></app-text-field-multi>

    <app-single-choice-field
      *ngIf="field.type === CustomFormFieldType.singleChoice"
      [field]="field"
      [form]="form"
      [id]="id"
    ></app-single-choice-field>

    <app-multi-choice-field
      *ngIf="field.type === CustomFormFieldType.multiChoice"
      [field]="field"
      [form]="form"
      [id]="id"
    ></app-multi-choice-field>
  </div>
</form>
