import { ActivatedRoute } from '@angular/router'
import {
  AuthenticatedResult,
  OidcSecurityService
} from 'angular-auth-oidc-client'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { AuthService } from 'src/app/auth/auth.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public org: any
  user: any

  public isMenuCollapsed = true
  private isAuthenticatedSub: Subscription

  get navStyle() {
    if (this.org?.appSettings?.themeColor) {
      return {
        backgroundColor: this.org.appSettings.themeColor
      }
    }

    return {
      backgroundColor: '#163172'
    }
  }

  constructor(
    private auth: AuthService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private oidc: OidcSecurityService
  ) {}

  ngOnInit(): void {
    this.getData()
  }

  ngOnDestroy() {
    if (this.isAuthenticatedSub) {
      this.isAuthenticatedSub.unsubscribe()
    }
  }

  getData() {
    this.isAuthenticatedSub = this.oidc.isAuthenticated$.subscribe(
      (authStatus: AuthenticatedResult) => {
        if (authStatus.isAuthenticated) {
          this.auth.me().subscribe((user) => {
            this.user = user

            if (this.user) {
              this.auth.identify()
            }
          })
        } else {
          this.user = null
        }
      }
    )
  }

  async logout() {
    await this.auth.logout()
    this.isMenuCollapsed = true
  }

  async login() {
    this.auth.authorize()

    this.isMenuCollapsed = true
  }
}
