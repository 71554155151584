import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: number, format: string, unix: boolean): any {
    if (!value) {
      return null
    }

    const date = unix ? moment.unix(value) : moment(value)

    return date.format(format)
  }
}
