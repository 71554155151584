<div class="form-group">
  <label [for]="fieldId">
    {{ field.label }}
    <span *ngIf="field.required" class="text-danger">*</span>
  </label>

  <div
    class="custom-control custom-checkbox  mb-3"
    *ngFor="let option of field.options"
  >
    <input
      [name]="fieldId"
      class="custom-control-input"
      [ngClass]="invalidClass"
      [id]="'checkbox-' + fieldId"
      type="checkbox"
      [formControl]="control"
      [value]="option"
    />
    <label class="custom-control-label" [for]="'checkbox-' + fieldId">
      {{ option }}
    </label>
  </div>
  <div class="invalid-feedback">
    This field is required
  </div>
  <small
    *ngIf="field.hintRendered"
    [id]="'help-' + fieldId"
    class="form-text text-muted"
    [innerHtml]="field.hintRendered"
  ></small>
</div>
