import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'sortDate'
})
export class SortDatePipe implements PipeTransform {
  private direction: string
  private dateTime: boolean

  private parse(date: any): any {
    return new Date(this.dateTime ? parseFloat(date) : date)
  }

  private sort(a: any, b: any) {
    if (this.direction === 'desc') {
      return this.parse(b) - this.parse(a)
    } else {
      return this.parse(a) - this.parse(b)
    }
  }

  transform(
    value: any[],
    param: string,
    direction = 'asc',
    dateTime?: boolean
  ): any {
    this.direction = direction
    this.dateTime = dateTime

    return value.sort((a, b) => this.sort(a[param], b[param]))
  }
}
