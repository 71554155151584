<div class="form-group">
  <label [for]="fieldId">
    {{ field.label }}
    <span *ngIf="field.required" class="text-danger">*</span>
  </label>

  <ng-container *ngIf="field.options.length < 4">
    <div
      class="custom-control custom-radio mb-3"
      [ngClass]="invalidClass"
      *ngFor="let option of field.options"
    >
      <input
        [name]="fieldId"
        class="custom-control-input"
        [id]="'radio-' + fieldId + '-' + option"
        type="radio"
        [formControl]="control"
        [value]="option"
      />
      <label
        class="custom-control-label"
        [for]="'radio-' + fieldId + '-' + option"
      >
        {{ option }}
      </label>
    </div>
  </ng-container>
  <select
    *ngIf="field.options.length > 3"
    class="custom-select form-control-alternative"
    [ngClass]="invalidClass"
    [formControl]="control"
    [id]="fieldId"
    [name]="fieldId"
  >
    <option [ngValue]="null" selected="selected" disabled></option>
    <option *ngFor="let option of field.options" [value]="option">
      {{ option }}
    </option>
  </select>

  <div class="invalid-feedback">
    This field is required
  </div>
  <small
    *ngIf="field.hintRendered"
    [id]="'help-' + fieldId"
    class="form-text text-muted"
    [innerHtml]="field.hintRendered"
  ></small>
</div>
