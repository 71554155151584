import { __extends } from "tslib";
import "../utilities/globals/index.js";
import { isNonEmptyArray } from "../utilities/index.js";
export function isApolloError(err) {
  return err.hasOwnProperty('graphQLErrors');
}
var generateErrorMessage = function (err) {
  var message = '';
  if (isNonEmptyArray(err.graphQLErrors) || isNonEmptyArray(err.clientErrors)) {
    var errors = (err.graphQLErrors || []).concat(err.clientErrors || []);
    errors.forEach(function (error) {
      var errorMessage = error ? error.message : 'Error message not found.';
      message += "".concat(errorMessage, "\n");
    });
  }
  if (err.networkError) {
    message += "".concat(err.networkError.message, "\n");
  }
  message = message.replace(/\n$/, '');
  return message;
};
var ApolloError = function (_super) {
  __extends(ApolloError, _super);
  function ApolloError(_a) {
    var graphQLErrors = _a.graphQLErrors,
      clientErrors = _a.clientErrors,
      networkError = _a.networkError,
      errorMessage = _a.errorMessage,
      extraInfo = _a.extraInfo;
    var _this = _super.call(this, errorMessage) || this;
    _this.name = 'ApolloError';
    _this.graphQLErrors = graphQLErrors || [];
    _this.clientErrors = clientErrors || [];
    _this.networkError = networkError || null;
    _this.message = errorMessage || generateErrorMessage(_this);
    _this.extraInfo = extraInfo;
    _this.__proto__ = ApolloError.prototype;
    return _this;
  }
  return ApolloError;
}(Error);
export { ApolloError };
