import { UntypedFormGroup } from '@angular/forms'
import { CustomForm, CustomFormFieldType } from './../form.type'
import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent implements OnInit {
  @Input() customForm: CustomForm
  @Input() form: UntypedFormGroup
  @Input() id: string

  CustomFormFieldType = CustomFormFieldType

  get fields() {
    return this.customForm.fields.sort((f1, f2) => f1.order - f2.order)
  }

  constructor() {}

  ngOnInit() {}
}
