<div [formGroup]="form">
  <div class="form-group">
    <label for="address">Address</label>
    <input
      type="text"
      class="form-control form-control-alternative"
      id="address"
      formControlName="line1"
    />
    <div class="invalid-feedback">Please enter an address</div>
  </div>

  <div class="form-group">
    <button *ngIf="!showLine2" class="btn btn-link" (click)="toggleShowLine2()">
      + Add Line 2
    </button>
    <div *ngIf="showLine2">
      <label for="address2">
        Address Line 2
        <a href="javascript:void(0)" (click)="toggleShowLine2()"> Remove </a>
      </label>
      <input
        type="text"
        class="form-control form-control-alternative"
        id="address2"
        formControlName="line2"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="col-3 form-group">
      <label for="zip">Postal Code</label>
      <input
        type="text"
        class="form-control form-control-alternative"
        id="zip"
        formControlName="postalCode"
      />
      <div class="invalid-feedback">Please enter a postal code</div>
    </div>
    <div class="col-5 form-group">
      <label for="city">City</label>
      <input
        type="text"
        class="form-control form-control-alternative"
        id="city"
        formControlName="city"
      />
      <div class="invalid-feedback">Please enter a city</div>
    </div>
    <div class="col-4 form-group">
      <label for="state">State / Province</label>
      <select
        class="custom-select form-control-alternative"
        id="state"
        formControlName="state"
      >
        <optgroup
          *ngFor="let country of regionsSvc.countries"
          [label]="country.name"
        >
          <option
            *ngFor="let region of regionsSvc.regions[country.id]"
            [ngValue]="region.id"
          >
            {{ region.name }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
</div>
