import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormControl,
  Validators
} from '@angular/forms'
import { CustomFormField } from './../form.type'
import { Input, OnInit, Component } from '@angular/core'

@Component({
  selector: 'app-field-base',
  template: `
    <div>field-base works!</div>
  `
})
export class FieldBaseComponent implements OnInit {
  @Input() field: CustomFormField
  @Input() form: UntypedFormGroup
  @Input() id: string

  control: AbstractControl

  get fieldId() {
    return `field-${this.field.id}-${this.id}`
  }

  get invalidClass() {
    return { 'is-invalid': this.control.invalid && this.control.touched }
  }

  ngOnInit() {
    this.control = new UntypedFormControl(null, [
      this.field.required ? Validators.required : Validators.nullValidator
    ])
    this.form.addControl(this.field.id, this.control)
  }
}
