import { FieldBaseComponent } from './../field-base'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-text-field-multi',
  templateUrl: './text-field-multi.component.html',
  styleUrls: ['./text-field-multi.component.scss']
})
export class TextFieldMultiComponent extends FieldBaseComponent implements OnInit {
  constructor() {
    super()
  }
}
