<footer class="footer">
  <div class="container d-flex justify-content-between">
    <ul class="list-inline">
      <li class="list-inline-item">
        <a href="https://e1.lc/app-tos" target="_blank" rel="noopener">
          Terms of Service
        </a>
      </li>
      <li class="list-inline-item">
        <a href="https://e1.lc/app-privacy" target="_blank" rel="noopener">
          Privacy Policy
        </a>
      </li>
    </ul>

    <ul class="list-inline">
      <li class="list-inline-item">
        ⚡ by
        <a href="https://e1.lc/e1-reg-referral" target="_blank" rel="noopener">
          eventOne
        </a>
      </li>
    </ul>
  </div>
</footer>
