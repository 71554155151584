import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() size = 3

  get sizeStyle() {
    return {
      width: `${this.size}rem`,
      height: `${this.size}rem`
    }
  }

  constructor() {}

  ngOnInit() {}
}
