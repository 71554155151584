export enum CustomFormFieldType {
  phone = 'phone',
  text = 'text',
  textMulti = 'textMulti',
  singleChoice = 'singleChoice',
  multiChoice = 'multiChoice'
}

export interface CustomFormField {
  id: string
  type: CustomFormFieldType
  label: string
  hintRendered: string
  required: boolean
  order: number
  options: string[]
}

export enum CustomFormType {
  attendee,
  order
}

export interface CustomForm {
  id: string
  name: string
  descriptionRendered: string
  showHeader: boolean
  type: CustomFormType
  fields: CustomFormField[]
  admissionIds: string[]
  promoIds: string[]
  eventId: string
}
