import { invariant } from "../globals/index.js";
import { visit, BREAK } from 'graphql';
export function shouldInclude(_a, variables) {
  var directives = _a.directives;
  if (!directives || !directives.length) {
    return true;
  }
  return getInclusionDirectives(directives).every(function (_a) {
    var directive = _a.directive,
      ifArgument = _a.ifArgument;
    var evaledValue = false;
    if (ifArgument.value.kind === 'Variable') {
      evaledValue = variables && variables[ifArgument.value.name.value];
      __DEV__ ? invariant(evaledValue !== void 0, "Invalid variable referenced in @".concat(directive.name.value, " directive.")) : invariant(evaledValue !== void 0, 40);
    } else {
      evaledValue = ifArgument.value.value;
    }
    return directive.name.value === 'skip' ? !evaledValue : evaledValue;
  });
}
export function getDirectiveNames(root) {
  var names = [];
  visit(root, {
    Directive: function (node) {
      names.push(node.name.value);
    }
  });
  return names;
}
export var hasAnyDirectives = function (names, root) {
  return hasDirectives(names, root, false);
};
export var hasAllDirectives = function (names, root) {
  return hasDirectives(names, root, true);
};
export function hasDirectives(names, root, all) {
  var nameSet = new Set(names);
  var uniqueCount = nameSet.size;
  visit(root, {
    Directive: function (node) {
      if (nameSet.delete(node.name.value) && (!all || !nameSet.size)) {
        return BREAK;
      }
    }
  });
  return all ? !nameSet.size : nameSet.size < uniqueCount;
}
export function hasClientExports(document) {
  return document && hasDirectives(['client', 'export'], document, true);
}
function isInclusionDirective(_a) {
  var value = _a.name.value;
  return value === 'skip' || value === 'include';
}
export function getInclusionDirectives(directives) {
  var result = [];
  if (directives && directives.length) {
    directives.forEach(function (directive) {
      if (!isInclusionDirective(directive)) return;
      var directiveArguments = directive.arguments;
      var directiveName = directive.name.value;
      __DEV__ ? invariant(directiveArguments && directiveArguments.length === 1, "Incorrect number of arguments for the @".concat(directiveName, " directive.")) : invariant(directiveArguments && directiveArguments.length === 1, 41);
      var ifArgument = directiveArguments[0];
      __DEV__ ? invariant(ifArgument.name && ifArgument.name.value === 'if', "Invalid argument for the @".concat(directiveName, " directive.")) : invariant(ifArgument.name && ifArgument.name.value === 'if', 42);
      var ifValue = ifArgument.value;
      __DEV__ ? invariant(ifValue && (ifValue.kind === 'Variable' || ifValue.kind === 'BooleanValue'), "Argument for the @".concat(directiveName, " directive must be a variable or a boolean value.")) : invariant(ifValue && (ifValue.kind === 'Variable' || ifValue.kind === 'BooleanValue'), 43);
      result.push({
        directive: directive,
        ifArgument: ifArgument
      });
    });
  }
  return result;
}
