import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { RegionsService } from 'src/app/services/regions.service'
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
  @Input() public form: UntypedFormGroup

  public showLine2 = false

  constructor(public regionsSvc: RegionsService) {}

  ngOnInit(): void {
    if (this.form) {
      this.form.addControl(
        'line1',
        new UntypedFormControl(null, [Validators.required])
      )
      this.form.addControl('line2', new UntypedFormControl(null, []))
      this.form.addControl(
        'postalCode',
        new UntypedFormControl(null, [Validators.required])
      )
      this.form.addControl('city', new UntypedFormControl(null, [Validators.required]))
      this.form.addControl(
        'state',
        new UntypedFormControl(null, [Validators.required])
      )
      this.form.addControl(
        'country',
        new UntypedFormControl(null, [Validators.required])
      )

      this.form.controls['state'].valueChanges.subscribe((region) => {
        if (region) {
          const country = this.regionsSvc.getCountryByRegion(region)
          this.form.controls['country'].setValue(country)
        }
      })
    }
  }

  public toggleShowLine2() {
    this.showLine2 = !this.showLine2

    if (this.showLine2 === false) {
      this.form.controls.line2.reset()
    }
  }
}
