import { FieldBaseComponent } from './../field-base'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-multi-choice-field',
  templateUrl: './multi-choice-field.component.html',
  styleUrls: ['./multi-choice-field.component.scss']
})
export class MultiChoiceFieldComponent extends FieldBaseComponent implements OnInit {
  constructor() {
    super()
  }
}
